import React, { useEffect, useState, useCallback } from "react";
import { Link, useLocation } from "react-router-dom";
import ApiService from "../api/ApiService";
import PageHeader from "../components/common/PageHeader";
import GoToTop from "../helpers/GoToTop";
import MetaData from "../helpers/MetaData";
import notification from "../helpers/notification";
import Skeleton from "react-loading-skeleton";

const Campaigns = () => {
  const [campaigns, setCampaigns] = useState(null);
  const [top, setTop] = useState(0);
  const location = useLocation();


  const scrollToElement3Page = () => {
    const element = document.getElementById('scrollToDonateFrom3ndPage');
    // console.log("elements",element);
    if(element) {
      element.scrollIntoView({behavior : 'smooth'})
    }
  };


  const fetchCampaigns = async () => {
    try {
      const res = await ApiService.fetchCampaigns();
      // console.log(res)
      setCampaigns(res);
    } catch (err) {
      console.log(err);
      notification("error", err.response.data.message);
    }
  };

  const festRef = useCallback((node) => {
    if (node !== null && location.hash) {
      setTop(node.offsetTop - 100);
    }
  });

  useEffect(() => {
    fetchCampaigns();
  }, []);

  useEffect(()=>{

    scrollToElement3Page();
    },[])

  return (
    <>
      <MetaData title="Campaigns - ISKCON of Bhiwandi" />
      <PageHeader title="Donate" imgSrc="/images/page-header/13.webp" />
      <section className="campaigns-wrap" ref={festRef}>
        <div className="container">
          <div  id="scrollToDonateFrom3ndPage" className="row">
            <div className="col-lg-10 mx-auto">
              <div className="heading">
                <h2 className="head-1 text-uppercase">
                  turn your money into prasad
                </h2>
                <p className="b-line">
                  If you would like to make a donation towards a particular area
                  of activity, please select an option from below. ISKCON relies
                  entirely on voluntary donations and so every donation counts.
                  Please note that donation is processed on a secure site.
                </p>
              </div>
            </div>
          </div>
          <div className="campaign-card-wrap mt-5 pt-5">
            {!campaigns && <CampaignCardSkeleton />}
            <div className="row">
              {campaigns?.map((campaign) => (
                <div key={campaign._id} className="col-lg-4 col-sm-6 mb-5">
                  <div className="campaign-card">
                    <div className="card-figure">
                      <figure className="mb-0">
                        <img
                          src={`${process.env.REACT_APP_S3_URL}/uploads/campaigns/payment-form-images/${campaign.paymentFormImage}`}
                          alt=""
                        />
                      </figure>
                      <div className="campaign-title">
                        <h4>{campaign.title == "Makar Kumbh" ? "Makar Sankranti and Kumbh Annadaan":campaign.title}</h4>
                      </div>
                    </div>
                    <div className="short-description">
                      <h6>{campaign.ctaTitle}</h6>
                      <div className="d-flex justify-content-center">
                        <a
                          href={`/${campaign.slug}`}
                          className="custom-btn-cls box-hover m-0 mt-3"
                        >
                          donate now
                        </a>
                        {/* <Link
                          to={`/${campaign.slug}`}
                          className="custom-btn-cls box-hover m-0 mt-3"
                        >
                          donate now
                        </Link> */}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
              {campaigns && (
                <>
                  <div className="col-lg-4 col-sm-6 mb-5">
                    <div className="campaign-card">
                      <div className="card-figure">
                        <figure className="mb-0">
                          <img
                            src="/images/campaign/foraign-donation.webp"
                            alt=""
                          />
                        </figure>
                        <div className="campaign-title">
                          <h4>Foreign Donation</h4>
                        </div>
                      </div>
                      <div className="short-description">
                        <h6>Help us to do Annadaan</h6>
                        <div className="d-flex justify-content-center">
                          <a
                            target="_blank"
                            href="https://milaap.org/fundraisers/support-poor-people-in-bhiwandi"
                            className="custom-btn-cls box-hover m-0 mt-3"
                          >
                            donate now
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-sm-6 mb-5">
                    <div className="campaign-card">
                      <div className="card-figure">
                        <figure className="mb-0">
                          <img
                            src="/images/campaign/life-membership-campaign.webp"
                            alt=""
                          />
                        </figure>
                        <div className="campaign-title">
                          <h4>Life Membership</h4>
                        </div>
                      </div>
                      <div className="short-description">
                        <h6>
                          Gain various benefits & always stay connected with
                          ISKCON Temples
                        </h6>
                        <div className="d-flex justify-content-center">
                          <Link
                            to="/life-membership#campaign"
                            className="custom-btn-cls box-hover m-0 mt-3"
                          >
                            donate now
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-sm-6 mb-5">
                    <div className="campaign-card">
                      <div className="card-figure">
                        <figure className="mb-0">
                          <img
                            src="/images/campaign/general-donation-campaign.webp"
                            alt=""
                          />
                        </figure>
                        <div className="campaign-title">
                          <h4>General Donations</h4>
                        </div>
                      </div>
                      <div className="short-description">
                        <h6>Support us with any kind of Donation</h6>
                        <div className="d-flex justify-content-center">
                          <a
                            target="_blank"
                            href="https://www.instamojo.com/iskconbhiwandi/"
                            className="custom-btn-cls box-hover m-0 mt-3"
                          >
                            donate now
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </section>
      <GoToTop top={top} />
    </>
  );
};

const CampaignCardSkeleton = () => {
  return (
    <div className="row">
      <div className="col-lg-4 col-sm-6 mb-5">
        <div className="campaign-card skeleton">
          <div className="card-figure">
            <Skeleton height={250} width={"100%"} />
            <div className="campaign-title">
              <Skeleton baseColor="#d3d3d3" width={"100%"} height={40} />
            </div>
          </div>
          <div className="short-description">
            <Skeleton width={"80%"} height={30} />
            <div className="d-flex justify-content-center mt-2">
              <Skeleton height={40} width={150} />
            </div>
          </div>
        </div>
      </div>
      <div className="col-lg-4 col-sm-6 mb-5">
        <div className="campaign-card skeleton">
          <div className="card-figure">
            <Skeleton height={250} width={"100%"} />
            <div className="campaign-title">
              <Skeleton baseColor="#d3d3d3" width={"100%"} height={40} />
            </div>
          </div>
          <div className="short-description">
            <Skeleton width={"80%"} height={30} />
            <div className="d-flex justify-content-center mt-2">
              <Skeleton height={40} width={150} />
            </div>
          </div>
        </div>
      </div>
      <div className="col-lg-4 col-sm-6 mb-5">
        <div className="campaign-card skeleton">
          <div className="card-figure">
            <Skeleton height={250} width={"100%"} />
            <div className="campaign-title">
              <Skeleton baseColor="#d3d3d3" width={"100%"} height={40} />
            </div>
          </div>
          <div className="short-description">
            <Skeleton width={"80%"} height={30} />
            <div className="d-flex justify-content-center mt-2">
              <Skeleton height={40} width={150} />
            </div>
          </div>
        </div>
      </div>
      <div className="col-lg-4 col-sm-6 mb-5">
        <div className="campaign-card skeleton">
          <div className="card-figure">
            <Skeleton height={250} width={"100%"} />
            <div className="campaign-title">
              <Skeleton baseColor="#d3d3d3" width={"100%"} height={40} />
            </div>
          </div>
          <div className="short-description">
            <Skeleton width={"80%"} height={30} />
            <div className="d-flex justify-content-center mt-2">
              <Skeleton height={40} width={150} />
            </div>
          </div>
        </div>
      </div>
      <div className="col-lg-4 col-sm-6 mb-5">
        <div className="campaign-card skeleton">
          <div className="card-figure">
            <Skeleton height={250} width={"100%"} />
            <div className="campaign-title">
              <Skeleton baseColor="#d3d3d3" width={"100%"} height={40} />
            </div>
          </div>
          <div className="short-description">
            <Skeleton width={"80%"} height={30} />
            <div className="d-flex justify-content-center mt-2">
              <Skeleton height={40} width={150} />
            </div>
          </div>
        </div>
      </div>
      <div className="col-lg-4 col-sm-6 mb-5">
        <div className="campaign-card skeleton">
          <div className="card-figure">
            <Skeleton height={250} width={"100%"} />
            <div className="campaign-title">
              <Skeleton baseColor="#d3d3d3" width={"100%"} height={40} />
            </div>
          </div>
          <div className="short-description">
            <Skeleton width={"80%"} height={30} />
            <div className="d-flex justify-content-center mt-2">
              <Skeleton height={40} width={150} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Campaigns;
